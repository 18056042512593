import Auth from '@/infrastructure/api/helper/Auth';
import Client from '@/infrastructure/api/helper/Client';

import {AuthenticationRequest, AuthenticationToken} from '@/types/authentication/Authentication';
import {AuthorizedAccount} from '@/types/authentication/Account';
import {Office} from '@/types/office/Office';
import {Assessor, AssessorGetRequest, AssessorRegisterRequest} from '@/types/assessor/Assessor';
import {
  InquirySummary,
  InquiriesGetRequest,
  Inquiry,
  InquiryGetRequest,
  InquiryRegisterRequest, InquiryAnswerRegisterRequest,
} from '@/types/inquiry/Inquiry';
import {Material, MaterialGetRequest} from '@/types/material/Material';
import {Content, ContentGetRequest} from '@/types/material/Content';
import {TestContentRegisterRequest} from '@/types/content/TestContent';
import {ViewContentRegisterRequest} from '@/types/content/ViewContent';
import {LatestViewContentRegisterRequest} from '@/types/content/LatestViewContent';
import {Information} from '@/types/information/Information';
import {Question} from '@/types/content/Question';
import {ContentExamResultRegisterRequest, ContentExams} from '@/types/content/ContentExams';
import {ContentExamAnswers} from '@/types/content/ContentExamAnswers';
import {ExamResultsGetRequest, ExamResult, ExamResultsGetResponse} from '@/types/content/ExamResult';

export default {
  async getAssessor(request: AssessorGetRequest): Promise<Assessor> {
    const client = new Client();
    const response = await client.get(`/api/assessor/${request.assessorNumber}`);
    return response.data;
  },

  async registerAssessor(request: AssessorRegisterRequest): Promise<void> {
    const client = new Client();
    await client.post('/api/assessor', request);
  },

  async authenticate(request: AuthenticationRequest): Promise<AuthenticationToken> {
    const client = new Client();
    const response = await client.post('/api/authenticate', request);
    return response.data;
  },

  async refresh(refreshToken: string): Promise<string> {
    const client = new Client();
    const response = await client.post('/api/authenticate/token', {
      refreshToken,
    });
    return response.data.accessToken;
  },

  async getAccount(): Promise<AuthorizedAccount> {
    const auth = new Auth();
    const response = await auth.get('/api/authenticate/account');
    return response.data;
  },

  async getOffices(): Promise<Office[]> {
    const client = new Client();
    const response = await client.get('/api/offices');
    return response.data;
  },

  async getInquiries(request: InquiriesGetRequest): Promise<InquirySummary[]> {
    const auth = new Auth();
    const response = await auth.post('/api/inquiries', request);
    return response.data;
  },

  async getInquiry(request: InquiryGetRequest): Promise<Inquiry> {
    const auth = new Auth();
    const response = await auth.get(`/api/inquiries/${request.inquirySeq}`);
    return response.data;
  },

  async registerInquiry(request: InquiryRegisterRequest): Promise<void> {
    const auth = new Auth();
    await auth.post('/api/inquiry', request);
  },

  async registerInquiryAnswer(request: InquiryAnswerRegisterRequest): Promise<void> {
    const auth = new Auth();
    // tslint:disable-next-line:max-line-length
    await auth.post(`/api/inquiries/${request.inquirySeq}/answer`, {answersAt: request.answersAt, title: request.title, content: request.content});
  },

  async getMaterials(): Promise<Material[]> {
    const auth = new Auth();
    const response = await auth.get('/api/materials');
    return response.data;
  },

  async getMaterial(request: MaterialGetRequest): Promise<Material> {
    const auth = new Auth();
    const response = await auth.get(`/api/materials/${request.materialId}`);
    return response.data;
  },

  async getContent(request: ContentGetRequest): Promise<Content> {
    const auth = new Auth();
    const response = await auth.get(`/api/contents/${request.contentId}`);
    return response.data;
  },
  async getContentExam(request: ContentGetRequest): Promise<ContentExams> {
    const auth = new Auth();
    const response = await auth.get(`/api/contents/exams/${request.contentId}`);
    return response.data;
  },
  async getContentExamAnswer(request: { contentExamId: number}): Promise<ContentExamAnswers> {
    const auth = new Auth();
    const response = await auth.get(`/api/contents/exam/${request.contentExamId}/answers`);
    return response.data;
  },
  async registerContentExamResult(request: ContentExamResultRegisterRequest): Promise<void> {
    const auth = new Auth();
    await auth.post('/api/assessor/exam/result', request);
  },


  async setSignedCookies(): Promise<void> {
    const auth = new Auth();
    await auth.get('/api/signed/cookies');
  },

  async registerTestContent(request: TestContentRegisterRequest): Promise<void> {
    const auth = new Auth();
    await auth.post('/api/assessor/test/content', request);
  },

  async registerViewContent(request: ViewContentRegisterRequest): Promise<void> {
    const auth = new Auth();
    await auth.post('/api/assessor/view/content', request);
  },

  async registerLatestViewContent(request: LatestViewContentRegisterRequest): Promise<void> {
    const auth = new Auth();
    await auth.post('/api/assessor/view/content/latest', request);
  },

  async registerCompletion(): Promise<void> {
    const auth = new Auth();
    await auth.post('/api/assessor/completion');
  },

  async getInformationHead(): Promise<Information> {
    const client = new Client();
    const response = await client.get('/api/information/head');
    return response.data;
  },

  async getInformation(): Promise<Information[]> {
    const auth = new Auth();
    const response = await auth.get('/api/information');
    return response.data;
  },

  async getExamResults(request: ExamResultsGetRequest): Promise<ExamResultsGetResponse> {
    const auth = new Auth();
    const response = await auth.post('/api/assessor/exam/results', request);
    return response.data;
  },
};
