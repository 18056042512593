
  import {Component, Emit, Prop, Vue} from 'vue-property-decorator';

  @Component({})
  export default class ConfirmModal extends Vue {
    @Prop({})
    name!: string;
    @Prop({})
    title!: string;
    @Prop({default: '340px'})
    width!: string;

    @Emit('confirm')
    confirm(result: boolean): boolean {
      return result;
    }

    // method
    touch(result: boolean, event: Event): void {
      this.confirm(result);
      event.preventDefault();
    }
  }
