import {Content} from '@/types/material/Content';
import {ProgressStatus} from '@/types/content/ProgressStatus';

export interface Material {
  id: number;
  name: string;
  statuses: ProgressStatus[];
  nextStatus: ProgressStatus;
  contents: Content[] | null;
}

export const ExerciseMaterialId: number = 7000;

export const EmptyMaterial: Material = {
  id: 0,
  name: '',
  statuses: [],
  nextStatus: ProgressStatus.Unlearned,
  contents: [],
};

export interface MaterialGetRequest {
  materialId: number;
}
