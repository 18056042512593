
  import {Component, Watch, Vue} from 'vue-property-decorator';
  import {loginModule} from '@/stores/authentication/Account';
  import {tokenModule} from '@/stores/authentication/Authentication';
  import ConfirmModal from '@/components/molecules/modal/ConfirmModal.vue';
  import Logo from '@/components/atoms/Logo.vue';
  import api from '@/infrastructure/api/API';

  @Component({
    components: {
      ConfirmModal,
      Logo,
    },
  })
  export default class Header extends Vue {
    // data
    loggedIn: boolean = false;
    message: string = 'ログアウトします。よろしいですか？';

    @Watch('$route')
    routeUpdate() {
      this.initialize();
      if (this.loggedIn) {
        loginModule.getAccount();
        this.setSignedCookies();
      }
    }

    // computed
    get assessorNumber(): string {
      return loginModule.assessorNumber;
    }

    get hasUnreadAnswer(): boolean {
      return loginModule.hasUnreadAnswer;
    }
    get hasUserInquiry(): boolean {
      return loginModule.hasUserInquiry;
    }

    get title(): string {
      return this.$router.currentRoute.meta.title;
    }

    get fullTitle(): string {
      if (!this.title) {
        return 'JAAI';
      }
      return 'JAAI | ' + this.title;
    }

    // method
    initialize(): void {
      document.title = this.fullTitle;
      this.loggedIn = this.$router.currentRoute.meta.requiresAuth;
    }

    openConfirmModal(): void {
      this.$modal.show('LogoutConfirmModal');
    }

    closeConfirmModal(): void {
      this.$modal.hide('LogoutConfirmModal');
    }

    toMyPage(): void {
      this.$router.push({path: '/'}).catch(() => {
        // @ts-ignore block is empty.
      });
    }

    toInquiryForm(): void {
      this.$router.push({path: '/inquiry/form'}).catch(() => {
        // @ts-ignore block is empty.
      });
    }

    toInquiry(): void {
      this.$router.push({path: '/inquiry'}).catch(() => {
        // @ts-ignore block is empty.
      });
    }
    toResultList(): void {
      this.$router.push({name: 'examResult'}).catch(() => {
        // @ts-ignore block is empty.
      });
    }

    logout(confirmed: boolean): void {
      if (confirmed) {
        tokenModule.clear();
        loginModule.clear();
        this.$router.push({name: 'signin'});
      }
      this.closeConfirmModal();
    }

    setSignedCookies(): void {
      api.setSignedCookies();
    }

    // lifecycle hooks
    created(): void {
      this.initialize();
    }
  }
