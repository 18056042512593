import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VModal from 'vue-js-modal';
import VideoPlayer from 'vue-videojs7';
import pdf from 'vue-pdf';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

// https://github.com/FortAwesome/vue-fontawesome
import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import DateFormat from '@/filter/DateFormat';

Vue.config.productionTip = false;


// use
Vue.use(VModal);
Vue.use(VideoPlayer);

library.add(fas);
Vue.component('v-icon', FontAwesomeIcon);

Vue.filter('dateFormat', DateFormat);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
