import Vue from 'vue';
import Router, {NavigationGuard} from 'vue-router';
import {tokenModule} from '@/stores/authentication/Authentication';
import {loginModule} from '@/stores/authentication/Account';
import {AuthenticationToken} from '@/types/authentication/Authentication';
import {AuthorizedAccount} from '@/types/authentication/Account';

Vue.use(Router);

const jaaiassessor: string | null = sessionStorage.getItem('jaaiassessor');

if (jaaiassessor) {
  const token: AuthenticationToken = JSON.parse(jaaiassessor).token.authenticationToken;
  tokenModule.setAuthenticationToken(token);
}

const guards: NavigationGuard<Vue> = (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!tokenModule.authorized) {
      next({path: '/signin'});
      return;
    }
    const storage: string | null = sessionStorage.getItem('jaaiassessor');
    if (storage) {
      const account: AuthorizedAccount = JSON.parse(storage).account.authorizedAccount;
      if (account) {
        loginModule.setAccount(account);
      }
    }
  }
  next();
};

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/signin',
      name: 'signin',
      component: () => import('@/views/sign/SignIn.vue'),
      meta: {requiresAuth: false},
      props: true,
    },
    {
      path: '/',
      redirect: '/mypage',
    },
    {
      path: '/mypage',
      name: 'mypage',
      component: () => import('@/views/mypage/MyPage.vue'),
      meta: {title: 'マイページ', requiresAuth: true},
    },
    {
      path: '/certificateComp',
      name: 'certificateComp',
      component: () => import('@/views/mypage/CertificateCompletetion.vue'),
      meta: {title: 'マイページ', requiresAuth: true},
    },
    {
      path: '/inquiry',
      name: 'inquiry',
      component: () => import('@/views/inquiry/InquiryList.vue'),
      meta: {title: 'お問い合わせ一覧', requiresAuth: true},
    },
    {
      path: '/inquiry/form',
      name: 'inquiryForm',
      component: () => import('@/views/inquiry/InquiryForm.vue'),
      meta: {title: 'お問い合わせ登録', requiresAuth: true},
    },
    {
      path: '/inquiry/confirm',
      name: 'inquiryConfirm',
      component: () => import('@/views/inquiry/InquiryConfirm.vue'),
      meta: {title: 'お問い合わせ確認', requiresAuth: true},
    },
    {
      path: '/inquiry/:seq',
      name: 'inquiryDetailForm',
      component: () => import('@/views/inquiry/InquiryDetailForm.vue'),
      meta: {title: 'お問い合わせ詳細', requiresAuth: true},
    },
    {
      path: '/inquiry/:seq/confirm',
      name: 'inquiryDetailFormConfirm',
      component: () => import('@/views/inquiry/InquiryDetailFormConfirm.vue'),
      meta: {title: '返信内容確認', requiresAuth: true},
    },
    {
      path: '/material/:id',
      name: 'material',
      component: () => import('@/views/material/MaterialDetail.vue'),
      meta: {title: '動画一覧', requiresAuth: true},
    },
    {
      path: '/content/:id',
      name: 'content',
      component: () => import('@/views/material/ContentDetail.vue'),
      meta: {title: '動画', requiresAuth: true},
    },
    {
      path: '/content/:id/test',
      name: 'test',
      component: () => import('@/views/material/TestDetail.vue'),
      meta: {title: '小テスト', requiresAuth: true},
    },
    {
      path: '/result',
      name: 'examResult',
      component: () => import('@/views/result/ExamResultList.vue'),
      meta: {title: '小テスト結果', requiresAuth: true},
    },
    {
      path: '/expire',
      name: 'expire',
      component: () => import('@/views/Expire.vue'),
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: () => import('@/views/Maintenance.vue'),
    },
    {
      path: '/systemerror',
      name: 'systemerror',
      component: () => import('@/views/SystemError.vue'),
    },
    {
      path: '*',
      name: 'notFound',
      component: () => import('@/views/NotFound.vue'),
    },
  ],
});

router.beforeEach(guards);

export default router;
