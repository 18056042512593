export interface AuthorizedAccount {
  assessorId: string;
  assessorNumber: string;
  officeCode: string;
  officeName: string;
  hasUnreadAnswer: boolean;
  hasUserInquiry: boolean;
  hasCompletion: boolean;
  isReadonly: boolean;
  isFastForwarding: boolean;
}

export const EmptyAuthorizedAccount: AuthorizedAccount = {
  assessorId: '',
  assessorNumber: '',
  officeCode: '',
  officeName: '',
  hasUnreadAnswer: false,
  hasUserInquiry: false,
  hasCompletion: false,
  isReadonly: false,
  isFastForwarding: false,
};
